import Vue from "vue";
import VueRouter from "vue-router";
import { canNavigate } from "@/libs/acl/routeProtection";
import usersRoutes from "./users";
import breedingsRoutes from "./breedings";
import companySettings from "./companySettings";
import termsRoutes from "./terms";
import companyProductRoutes from "./companyProduct";
import plantsRoutes from "./plants";
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from "@/auth/utils";
import store from "@/store";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...breedingsRoutes,
    ...companySettings,
    ...usersRoutes,
    ...termsRoutes,
    ...companyProductRoutes,
    ...plantsRoutes,
    {
      path: "/access-control",
      name: "access-control",
      component: () => import("@/views/extensions/acl/AccessControl.vue"),
      meta: {
        resource: "Auth",
        action: "read",
      },
    },

    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/Home.vue"),
      meta: {
        action: "read",
        resource: "Auth",
        redirectIfLoggedIn: false,
      },
    },

    {
      path: "/orders",
      name: "orders",
      component: () => import("@/views/Orders/Orders.vue"),
      meta: {
        action: "read",
        resource: "Auth",

        pageTitle: "orders",
        breadcrumb: [
          {
            text: "orders",
            active: true,
          },
        ],
      },
    },
    {
      path: "/order/:id",
      name: "OrderDetail",
      component: () => import("@/views/Orders/order-preview/orderPreview.vue"),
      exact: true,
      meta: {
        action: "read",
        resource: "Auth",
        navActiveLink: "orders",

        pageTitle: "order",
        breadcrumb: [
          {
            text: "genaralSettings",
          },
          {
            text: "orders",
            to: "/orders",
          },
          {
            text: "orderDetails",
            active: true,
          },
        ],
      },
    },
    {
      path: "/shop-prices",
      name: "shop-prices",
      component: () => import("@/views/Shop/Prices.vue"),
      meta: {
        action: "read",
        resource: "Auth",
      },
    },
    {
      path: "/daily-notification",
      name: "daily-notification",
      component: () => import("@/views/Notification.vue"),
      meta: {
        action: "read",
        resource: "Auth",

        pageTitle: "Daily Notification",
        breadcrumb: [
          {
            text: "Daily Notification",
            active: true,
          },
        ],
      },
    },
    {
      path: "/visits",
      name: "visits",
      component: () => import("@/views/Visits/Visits.vue"),
      meta: {
        action: "read",
        resource: "Auth",

        pageTitle: "visits",
        breadcrumb: [
          {
            text: "visits",
            active: true,
          },
        ],
      },
    },
    {
      path: "/prices",
      name: "prices",
      component: () => import("@/views/Prices/Prices.vue"),
      meta: {
        action: "read",
        resource: "Auth",

        pageTitle: "productPrices",
        breadcrumb: [
          {
            text: "productPrices",
            active: true,
          },
        ],
      },
    },
    {
      path: "/financial",
      name: "financial",
      component: () => import("@/views/Financial.vue"),
      meta: {
        action: "read",
        resource: "Auth",
        pageTitle: "Financial",
        breadcrumb: [
          {
            text: "Financial",
            active: true,
          },
        ],
      },
    },
    {
      path: "/reports",
      name: "reports",
      component: () => import("@/views/Reports.vue"),
      meta: {
        action: "read",
        resource: "Auth",
        pageTitle: "Reports",
        breadcrumb: [
          {
            text: "Reports",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "auth-login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/forgot-password",
      name: "auth-forgot-password",
      component: () => import("@/views/ForgotPassword.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/register",
      name: "auth-register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/pages/miscellaneous/not-authorized",
      name: "misc-not-authorized",
      component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Auth",
      },
    },
    {
      path: "/shop",
      name: "shop",
      component: () => import("@/views/Shop/Shop.vue"),
      meta: {
        action: "read",
        resource: "Auth",
      },
    },
    {
      path: "/as-if",
      name: "as-if",
      component: () => import("@/views/AsIf/AsIf.vue"),
      meta: {
        action: "read",
        resource: "Admin",
      },
    },
  ],
});

router.beforeEach((to, _, next) => {
  var selectedPlantId = store.state.app.selectedPlantId;

  if (
    (selectedPlantId === null ||
      selectedPlantId === undefined ||
      selectedPlantId === "" ||
      selectedPlantId === 0) &&
    1
  ) {
  }
  const isLoggedIn = isUserLoggedIn();
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser());
  }

  return next();
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
