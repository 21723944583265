/* eslint-disable prettier/prettier */
export default [
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/company/Terms/Terms.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "termsInfo",
      breadcrumb: [
        {
          text: "companySettings",
        },
        {
          text: "termsInfo",
          active: true,
        },
      ],
    },
  },
];
