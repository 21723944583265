/* eslint-disable prettier/prettier */
export default [
    {   
        action: "read",
        resource: "Auth",
        path: "/company/general",
        name: "general-settings",
        component: () => import("@/views/company/GeneralSettings.vue"),
        meta: {
            action: "read",
            resource: "Auth",
            pageTitle: "generalSettings",
            breadcrumb: [
                {
                    text: "generalSettings",
                    active: true,
                },
            ],
        },
    }, 

];
