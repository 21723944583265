/* eslint-disable prettier/prettier */
export default [

    {
        path: "/company-product",
        name: "companyProduct",
        component: () => import("@/views/CompanyProduct/CompanyProduct.vue"),
        meta: {
            action: "read",
            resource: "Auth",

            pageTitle: "companyProducts",
            breadcrumb: [
                
                {
                    text: "companyProducts",
                    active: true,
                },
            ],
        },
    },
];
