/* eslint-disable prettier/prettier */
export default [
    {
        path: "/breeding",
        name: "Breeding",
        component: () => import("@/views/Breedings/Breeding.vue"),
        meta: {
            action: "read",
            resource: "Auth",
            pageTitle: "Breeding",
            breadcrumb: [
                {
                    text: "Breeding",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/breeding/pregnancy-checking",
        name: "pregnancy-checking",
        component: () => import("@/views/Breedings/PregnancyChecking.vue"),
        exact: true,
        meta: {
            action: "read",
            resource: "Auth",
            pageTitle: "Pregnancy Checking",
            breadcrumb: [
                {
                    text: "Pregnancy Checking",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/breeding/calving",
        name: "calving",
        component: () => import("@/views/Breedings/Calving.vue"),
        exact: true,
        meta: {
            action: "read",
            resource: "Auth",
            pageTitle: "Calving",
            breadcrumb: [
                {
                    text: "Calving",
                    active: true,
                },
            ],
        },
    }, {
        path: "/breeding/weaning",
        name: "weaning",
        component: () => import("@/views/Breedings/Weaning.vue"),
        exact: true,
        meta: {
            action: "read",
            resource: "Auth",
            pageTitle: "Weaning",
            breadcrumb: [
                {
                    text: "Weaning",
                    active: true,
                },
            ],
        },
    }, {
        path: "/breeding/yearling",
        name: "yearling",
        component: () => import("@/views/Breedings/Yearling.vue"),
        exact: true,
        meta: {
            action: "read",
            resource: "Auth",
            pageTitle: "Yearling",
            breadcrumb: [
                {
                    text: "Yearling",
                    active: true,
                },
            ],
        },
    },
];
